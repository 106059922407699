import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
// core styles
import "./scss/volt.scss";
import "antd/dist/antd.css";
// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

// Customize JSON parse
JSON.parseSafe = (data) => {
  let result = null;
  if (data) {
    try {
      result = JSON.parse(data);
    } catch (error) {
      console.log(`[ParseSafe] data ${data}`);
    }
  }
  return result;
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        <ScrollToTop />
        <HomePage />
      </HashRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
