export const formatTimestampToDate = (date) => {
  if (!date) {
    return new Date();
  }
  let time = date;
  if (time?.toDate) {
    time = time.toDate();
  }
  return time;
};
