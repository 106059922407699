/* eslint-disable no-undef */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import { isProduction } from "../api/config";
require("firebase/functions");

export const getProjectName = () => {
  return isProduction() ? "beenalone-7373c" : "beenalone-dev";
};

export const getConfigFirebase = () => {
  return isProduction() ? firebaseConfigPro : firebaseConfig;
};

const firebaseConfig = {
  apiKey: "AIzaSyDV8OCup5D60LAdmfymibPvHSbPnqwgEMQ",
  authDomain: "beenalone-dev.firebaseapp.com",
  databaseURL: "https://beenalone-dev.firebaseio.com",
  projectId: "beenalone-dev",
  storageBucket: "beenalone-dev.appspot.com",
  messagingSenderId: "693832526199",
  appId: "1:693832526199:web:e162556f93f92c2c445972",
  measurementId: "G-716070LXRV",
  messagingKey:
    "AAAAoYukKXc:APA91bE1uT5tdD8y1V2a2nrIyCxv33-5dt8MMhmzUQhoCapU6SGNgC3YxgDxwQ0tT_ajzq0D_8Li56m585Fmr_RRDyvePx8XAy7WbjdZhzKY494XotEmr94YrO-ZQqWTZ0clbW4HTkGn",
};

const firebaseConfigPro = {
  apiKey: "AIzaSyAMfuUvAWP6izw4NT0bodLQPpm6JoTg-TI",
  authDomain: "beenalone-7373c.firebaseapp.com",
  databaseURL: "https://beenalone-7373c.firebaseio.com",
  projectId: "beenalone-7373c",
  storageBucket: "beenalone-7373c.appspot.com",
  messagingSenderId: "341447798740",
  appId: "1:341447798740:web:a8bcf17e92dea8ac64f041",
  measurementId: "G-PF0NLYMWKP",
  messagingKey:
    "AAAAT3_f79Q:APA91bE9NhyoROSuP2MqC0GWuV_KQsNbJD55xlapjY5RhYkb2aYlXkD-8_z9Y9u-kF6J1ZalHBJ2ObDfoS50P6c8_CkVcv_mUguAlqjAxWGefKnMg958iqMRsNlgkdwfNpay6okC_jl6",
};

const appDev = firebase.initializeApp(firebaseConfig, "dev");

const appPro = firebase.initializeApp(firebaseConfigPro, "pro");

export const app = isProduction() ? appPro : appDev;
export const getFirebaseApp = () => {
  return isProduction() ? appPro : appDev;
};
