import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Breadcrumb } from "@themesberg/react-bootstrap";
import { Empty, Input, message, Select, Table, Avatar, Button } from "antd";
import { getFirebaseApp, getProjectName } from "../../services/firebase";
import moment from "moment";
import { formatTimestampToDate } from "../../utils/DateUtil";

const { Option } = Select;

const FIND_TYPE = {
  ID: "id",
  USER_NAME: "Display Name",
  EMAIL: "email",
};

const FindUser = () => {
  const [data, setData] = useState([]);
  const [type, setType] = useState(FIND_TYPE.ID);
  const [loading, setLoading] = useState(false);

  const findUser = (keyword) => {
    setLoading(true);
    let query = getFirebaseApp().firestore().collection("users");

    switch (type) {
      case FIND_TYPE.ID:
        query = query.doc(keyword);
        break;
      case FIND_TYPE.USER_NAME:
        query = query.where("displayName", "==", keyword);
        break;
      case FIND_TYPE.EMAIL:
        query = query.where("email", "==", keyword);
        break;

      default:
        break;
    }

    query
      .get()
      .then((res) => {
        if (
          (!res.docs?.length && type !== FIND_TYPE.ID) ||
          (type === FIND_TYPE.ID && !res.exists)
        ) {
          setLoading(false);
          message.warn("Can't find user!");
          return;
        }
        let val =
          type === FIND_TYPE.ID
            ? [{ ...res.data(), id: res.id }]
            : res.docs.map((item) => ({ ...item.data(), id: item.id }));

        val.sort((a, b) => b.lastActive - a.lastActive);
        setLoading(false);
        console.log(val);
        setData(val);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const onSearch = (val) => {
    if (!val) {
      return;
    }
    findUser(val);
  };

  const handleChange = (val) => {
    setType(val);
  };

  const onViewDetail = (id) => () => {
    window.open(
      `https://console.firebase.google.com/u/0/project/${getProjectName()}/firestore/data/~2Fusers~2F${id}`
    );
  };

  const onPressAvatar = (photoURL) => () => {
    window.open(photoURL);
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Avatar",
      key: "operation",
      render: (val) => (
        <a onClick={onPressAvatar(val?.providerData?.[0]?.photoURL)}>
          <Avatar
            on
            src={val?.providerData?.[0]?.photoURL}
            shape="circle"
            size={50}
          />
        </a>
      ),
    },
    {
      title: "displayName",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "date",
      key: "date",
      dataIndex: "date",
      render: (val) => moment(formatTimestampToDate(val)).format("DD/MM/YYYY"),
    },
    {
      title: "Last Active",
      dataIndex: "lastActive",
      key: "lastActive",
      render: (value) => (
        <a key={value}>{moment(value).format("HH:mm DD/MM/YYYY")}</a>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (value) => (
        <Button
          key={value}
          onClick={() => {
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${value.latitude},${value.longitude}`
            );
          }}
        >
          View Location
        </Button>
      ),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (record) => (
        <Button key={record.id} type="ghost" onClick={onViewDetail(record.id)}>
          Open
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Find User</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="align-items-center">
          <Col xl={2}>
            <Select
              defaultValue={type}
              style={{ width: 150 }}
              onChange={handleChange}
            >
              <Option value={FIND_TYPE.ID}>{FIND_TYPE.ID.toUpperCase()}</Option>
              <Option value={FIND_TYPE.USER_NAME}>
                {FIND_TYPE.USER_NAME.toUpperCase()}
              </Option>
              <Option value={FIND_TYPE.EMAIL}>
                {FIND_TYPE.EMAIL.toUpperCase()}
              </Option>
            </Select>
          </Col>
          <Col xl={4}>
            <Input.Search
              placeholder="Search User"
              allowClear
              size="middle"
              enterButton
              onSearch={onSearch}
              loading={loading}
            />
          </Col>
        </Row>
      </div>

      {!data.length ? (
        <Empty />
      ) : (
        <Table dataSource={data} columns={columns} scroll={{ x: 1300 }} />
      )}
    </>
  );
};
export default FindUser;
