import axios from "axios";
import { isProduction } from "../api/config";
import { getProjectName } from "../services/firebase";

export const sendNotification = async (
  type,
  userInfo,
  data,
  { title = "", body = "" }
) => {
  // data["type"] = type;

  const fcmToken = userInfo.fcmToken;
  const userId = userInfo.uid || userInfo.id;
  if (!fcmToken) {
    console.log(`No fcm token for user: ${userId}`);
    return;
  }

  const payload = {
    data: data,
  };

  payload.notification = {
    body,
    title,
    sound: "default",
    // title_loc_args: JSON.stringify(titleArgs),
    // body_loc_args: JSON.stringify(bodyArgs),
    // badge: `${userInfo.unseen_notification_count || 0}`,
  };

  console.log(`Sent notification to user: ${userId}`);
  return axios.post(
    `https://fcm.googleapis.com/fcm/send`,
    {
      to: fcmToken,
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: [
          "key",
          isProduction()
            ? "AAAAT3_f79Q:APA91bE9NhyoROSuP2MqC0GWuV_KQsNbJD55xlapjY5RhYkb2aYlXkD-8_z9Y9u-kF6J1ZalHBJ2ObDfoS50P6c8_CkVcv_mUguAlqjAxWGefKnMg958iqMRsNlgkdwfNpay6okC_jl6"
            : "AAAAoYukKXc:APA91bE1uT5tdD8y1V2a2nrIyCxv33-5dt8MMhmzUQhoCapU6SGNgC3YxgDxwQ0tT_ajzq0D_8Li56m585Fmr_RRDyvePx8XAy7WbjdZhzKY494XotEmr94YrO-ZQqWTZ0clbW4HTkGn",
        ].join("="),
      },
    }
  );
};

export const openUserProfile = (id) => {
  window.open(
    `https://console.firebase.google.com/u/0/project/${getProjectName()}/firestore/data/~2Fusers~2F${id}`
  );
};

export const openFirestoreCollection = ({ collectionName, id }) => {
  window.open(
    `https://console.firebase.google.com/u/0/project/${getProjectName()}/firestore/data/~2F${collectionName}~2F${id}`
  );
};

export const convertURLSearchToObject = (string) => {
  if (!string) {
    return {};
  }
  var search = string.substring(1);
  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
};

export const removeEmptyField = (object) => {
  const obj = {};
  Object.keys(object).forEach((key) => {
    const value = object[key];
    if (value) {
      obj[key] = value;
    }
  });
  return obj;
};
