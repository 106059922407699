import axios, { AxiosRequestConfig } from "axios";
import { BEEN_ALONE_TOKEN_KEY, END_POINT } from "./config";

const axiosInstance = axios.create({
  baseURL: END_POINT
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const authToken = localStorage.getItem(BEEN_ALONE_TOKEN_KEY);
    if (authToken) {
      config.headers = { ...config.headers, Authorization: authToken };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const handleApiError = ({ error, reject }: { error: Error, reject: Function }) => {
  if (error?.message === "Network Error") {
    return;
  }
  reject(error);
};

export const requestPost = (path: string, params?: object, config?: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(path, params, config)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        handleApiError({ error, reject });
      });
  });
};

export const requestGet = (path: string, config?: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(path, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        handleApiError({ error, reject });
      });
  });
};

export const requestDelete = (
  path: string,
  params?: object,
  config?: AxiosRequestConfig
) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(path, { data: params, ...config })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        handleApiError({ error, reject });
      });
  });
};

export const requestPut = (
  path: string,
  params: Object,
  config: AxiosRequestConfig
) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(path, params, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        handleApiError({ error, reject });
      });
  });
};

export { axiosInstance };
