import { requestDelete, requestGet, requestPut } from "./common";
import { DEBUG_END_POINT_APPS } from "./config";

export default {
  getConfessionDetail(confessionId?: string) {
    return requestGet(`apps/v1/confessions/${confessionId}`, {
      baseURL: DEBUG_END_POINT_APPS,
    });
  },
  deleteConfession(confessionId?: string) {
    return requestDelete(`apps/v1/admin/confessions/${confessionId}`, {
      baseURL: DEBUG_END_POINT_APPS,
    });
  },
  getConfessionCommentDetail({
    confessionId,
    commentId,
  }: {
    confessionId: string;
    commentId: string;
  }) {
    return requestGet(
      `/apps/v1/confessions/${confessionId}/comment/${commentId}`,
      {
        baseURL: DEBUG_END_POINT_APPS,
      }
    );
  },
  getConfessionList({
    offset = 0,
    limit = 10,
    country,
    status,
  }: {
    offset?: number;
    limit?: number;
    country?: string;
    status?: 1 | 0;
  }) {

    const params = {
        offset,
        limit,
        status,
        country
      }

      if(!country){
        delete params.country
      }
    
    return requestGet(
      // @ts-ignore
      `apps/v1/admin/confessions/?${new URLSearchParams(params)}`,
      {
        baseURL: DEBUG_END_POINT_APPS,
      }
    );
  },
  approveConfession(confessionId: string) {
    return requestPut(
      `apps/v1/admin/confessions/${confessionId}/approve`,
      {},
      {
        baseURL: DEBUG_END_POINT_APPS,
      }
    );
  },
  deniedConfession(confessionId: string) {
    return requestPut(
      `apps/v1/admin/confessions/${confessionId}/denied`,
      {},
      {
        baseURL: DEBUG_END_POINT_APPS,
      }
    );
  },
};