export const Routes = {
  // pages
  DashboardOverview: { path: "/" },
  FindUser: { path: "/find-user" },
  Trash: { path: "/trash" },
  RequestFeature: { path: "/request-feature" },
  Settings: { path: "/settings" },
  PushNotification: { path: "/push-notification" },
  ReportUsers: { path: "/report-users" },
  ReportUsersV2: { path: "/v2/report-users" },
  BlockUser: { path: "/block-user" },
  BlockUserV2: { path: "/v2/block-user" },
  Room: { path: "/room" },
  Confession: { path: "/confession" },
  ConfessionPending: { path: "/confession-pending" },
  // =====
  Upgrade: { path: "/upgrade" },
  Signin: { path: "/sign-in" },
  Signup: { path: "/examples/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
