import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, message, Skeleton } from "antd";
import { getFirebaseApp } from "../../services/firebase";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import { useEffect } from "react";
import RequestActions, { FILTER_VALUE } from "./components/RequestActions";

const RequestFeature = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFirebaseApp()
      .firestore()
      .collection("features")
      .orderBy("quantityVote", "desc")
      .get()
      .then((res) => {
        setLoading(false);
        const val = res.docs.map((item) => ({ ...item.data(), id: item.id }));

        setData(val);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
    return () => {};
  }, []);

  const onChangeStatusItem = (id) => (val) => {
    getFirebaseApp()
      .firestore()
      .collection("features")
      .doc(id)
      .update({ status: val })
      .then(() => {
        message.success("Update Success!");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const renderAction = (text, record) => {
    return (
      <RequestActions
        key={record.id}
        defaultValue={record.status}
        onChangeValue={onChangeStatusItem(record.id)}
      />
    );
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: 300,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Quantity Vote",
      key: "quantityVote",
      dataIndex: "quantityVote",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.quantityVote - b.quantityVote,
    },
    {
      title: "Creator Name",
      dataIndex: "creatorName",
      key: "creatorName",
    },
    {
      title: "Creator Id",
      dataIndex: "creatorId",
      key: "creatorId",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: renderAction,
      defaultFilteredValue: [FILTER_VALUE.REQUESTING],
      filters: [
        {
          text: FILTER_VALUE.REQUESTING.toUpperCase(),
          value: FILTER_VALUE.REQUESTING,
        },
        {
          text: FILTER_VALUE.ACCEPTED.toUpperCase(),
          value: FILTER_VALUE.ACCEPTED,
        },
        {
          text: FILTER_VALUE.FINISHED.toUpperCase(),
          value: FILTER_VALUE.FINISHED,
        },
        {
          text: FILTER_VALUE.CANCELED.toUpperCase(),
          value: FILTER_VALUE.CANCELED,
        },
      ],
      onFilter: (value, record) =>
        record?.status?.indexOf?.(value) === 0 ||
        (value === FILTER_VALUE.REQUESTING && !record.status),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Request New Feature</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {loading ? (
        <Skeleton />
      ) : (
        <Table dataSource={data} columns={columns} scroll={{ x: 1300 }} />
      )}
    </>
  );
};
export default RequestFeature;
