import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import {
  Form,
  Input,
  Button,
  message,
  Select,
  Divider,
  Table,
  Tag,
  Typography,
  Radio,
  Image,
} from "antd";
import { getFirebaseApp } from "../../services/firebase";
import moment from "moment";
import { REPORT_TYPE } from "../../config/Const";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const validateMessages = {
  required: "${label} is required!",
};

const REASON = {
  1: {
    vi:
      "Bài viết confession của bạn đã bị xoá vì vi phạm quy định của Been Alone",
    en:
      "Your confession post has been removed for violating Been Alone's rules",
  },
  2: {
    vi:
      "Bình luận trong Confession của bạn đã bị xoá vì vi phạm quy định của Been Alone",
    en:
      "Your comment on Confession has been removed for violating Been Alone's rules",
  },
  3: {
    vi:
      "Bạn đã vi phạm quy định của Been Alone trong tính năng Trò chuyện cùng người lạ.",
    en: "You violated the rules of Been Alone in Chat with Strangers",
  },
  4: {
    vi:
      "Bạn đã vi phạm quy định của Been Alone trong tính năng Nhiệm vụ chung.",
    en: "You violated the rules of Been Alone in General Task List",
  },
};

function BlockUser(props) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [blockedData, setBlockedData] = useState([]);
  const [postInfo, setPostInfo] = useState();

  useEffect(() => {
    fetchUserBlockInfo();
    fetchPostInfo();
    return () => {};
  }, []);

  const fetchUserBlockInfo = async () => {
    const { userId } = props?.location?.state || {};
    if (userId) {
      const res = await getFirebaseApp()
        .firestore()
        .collection("blocked")
        .where("userId", "==", userId)
        .get()
        .catch((err) => {
          message.warn(err);
        });
      const arr = res.docs.map((obj) => obj.data());
      setBlockedData(arr);
    }
  };

  const fetchPostInfo = async () => {
    const { userId, type, confessionId, roomId, commentId, messageId } =
      props?.location?.state || {};
    if (userId) {
      let ref = null;
      switch (type) {
        case REPORT_TYPE.CONFESSION:
          ref = `confession/${confessionId}`;
          break;
        case REPORT_TYPE.CONFESSION_COMMENT:
          ref = `confession/${confessionId}/comments/${commentId}`;
          break;
        case REPORT_TYPE.CHAT_MESSAGE:
          ref = `rooms/${roomId}/messages/${messageId}`;
          break;

        default:
          break;
      }
      if (!ref) {
        return;
      }
      getFirebaseApp()
        .firestore()
        .doc(ref)
        .get()
        .then((res) => {
          setPostInfo({ ...res.data(), ref });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onFinish = async (res) => {
    setLoading(true);
    const requestBlockUser = getFirebaseApp()
      .functions()
      .httpsCallable("requestBlockUser");

    const params = {
      ...res.user,
      reason: REASON[res.user.reason],
    };

    if (res?.user?.reportId) {
      getFirebaseApp()
        .firestore()
        .collection("reports")
        .doc(res.user.reportId)
        .update({ status: 0 })
        .then(() => {
          message.success("Change status report success!");
        })
        .catch((err) => {
          message.warn("Change status report error: " + err);
        });
    }
    if (res?.user?.delete && postInfo?.ref) {
      getFirebaseApp()
        .firestore()
        .doc(postInfo.ref)
        .delete()
        .then(() => {
          message.success("Delete document success!");
        })
        .catch((err) => {
          message.warn("Delete document error: " + err);
        });
    }
    const blockRes = await requestBlockUser(params).catch((err) => {
      message.warn(err.message || "Something went wrong, please try again!");
    });
    setLoading(false);
    if (!blockRes?.data) {
      message.warn("Fail!");
      return;
    }
    fetchUserBlockInfo();
    form.resetFields();
    message.success("Success!");
  };

  const onChangeType = (value) => {
    form.setFieldsValue({ type: value });
  };

  const onChangeReason = (value) => {
    form.setFieldsValue({ reason: value });
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      fixed: "left",
      width: 150,
      render: (item) => {
        let color = "";
        let name = "";
        switch (item) {
          case "1":
            color = "geekblue";
            name = "Waring";
            break;
          case "2":
            color = "green";
            name = "24h";
            break;
          case "3":
            color = "volcano";
            name = "forever";
            break;

          default:
            break;
        }
        return <Tag color={color}>{name.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (item) => {
        let value = item;
        if (item?.vi) {
          value = item.vi;
        }
        return <Typography.Text>{value}</Typography.Text>;
      },
    },
    {
      title: "Report Id",
      key: "reportId",
      dataIndex: "reportId",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.created - b.created,
      render: (value) => (
        <a key={value}>{moment(value).format("DD/MM/YYYY")}</a>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent",
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Block User</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Form
        {...layout}
        form={form}
        initialValues={{
          user: {
            ...props.location.state,
            type: "1",
            reportId: props.location?.state?.id,
          },
        }}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        {postInfo?.ref && (
          <div>
            <Typography.Text>
              <Typography.Text>Post Info: </Typography.Text>
              <Tag>{postInfo?.content || postInfo?.text}</Tag>
            </Typography.Text>
            <Image src={postInfo?.image?.uri} width={100} />
          </div>
        )}
        <Form.Item
          name={["user", "userId"]}
          label="User Id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "type"]}
          label="Type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Select block type" onChange={onChangeType}>
            <Select.Option value="1">Warning</Select.Option>
            <Select.Option value="2">Block 24h</Select.Option>
            <Select.Option value="3">Block Forever</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name={["user", "reportId"]} label="Report Id">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "confessionId"]} label="Confession Id">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "commentId"]} label="Comment Id">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "taskId"]} label="Task Id">
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "commentChildrenId"]}
          label="Comment Children Id"
        >
          <Input />
        </Form.Item>
        <Form.Item name={["user", "roomId"]} label="Room Id">
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "reason"]}
          rules={[
            {
              required: true,
            },
          ]}
          label="Reason"
        >
          <Select
            dropdownStyle={{ width: 600 }}
            placeholder="Select block type"
            onChange={onChangeReason}
          >
            <Select.Option value={1}>Confession: {REASON[1].vi}</Select.Option>
            <Select.Option value={2}>
              Confession Comment: {REASON[2].vi}
            </Select.Option>
            <Select.Option value={3}>
              Find Strangers: {REASON[3].vi}
            </Select.Option>
            <Select.Option value={4}>Tasks: {REASON[4].vi}</Select.Option>
          </Select>
        </Form.Item>
        <Divider />
        <Form.Item
          name={["user", "reason"]}
          rules={[
            {
              required: true,
            },
          ]}
          label="Reason"
        >
          <Input aria-multiline />
        </Form.Item>
        <Form.Item name={["user", "title"]} label="Title">
          <Input aria-multiline />
        </Form.Item>
        {postInfo?.ref && (
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
            name={["user", "delete"]}
            label="Delete document or not?"
          >
            <Radio.Group>
              <Radio value={true}>With Delete Document</Radio>
              <Radio value={false}>Report only</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        {!!blockedData.length && (
          <Table
            dataSource={blockedData}
            columns={columns}
            scroll={{ x: 1300 }}
          />
        )}
      </Form>
    </>
  );
}

export default BlockUser;
