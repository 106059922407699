import { message, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getFirebaseApp } from "../../../services/firebase";

function TotalUsers() {
  const [totalUser, setTotalUser] = useState(0);
  const [loading, setLoading] = useState(0);
  useEffect(() => {
    // getFirebaseApp()
    //   .firestore()
    //   .collection("users")
    //   .get()
    //   .then((res) => {
    //     setLoading(false);
    //     setTotalUser(res?.docs?.length);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     message.error(err.message);
    //   });
    return () => {};
  }, []);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Typography.Text style={{ fontSize: 30, fontWeight: "bold" }}>
      Total Users: {totalUser}
    </Typography.Text>
  );
}

export default TotalUsers;
