import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import {
  Form,
  Input,
  Button,
  message,
  Select,
  Table,
  Tag,
  Typography,
  Radio,
  Image,
} from "antd";
import moment from "moment";
import { REPORT_TYPE } from "../../config/Const";
import { useLocation } from "react-router-dom";
import { convertURLSearchToObject } from "../../utils/Helper";
import userApi from "../../api/userApi";
import confessionApi from "../../api/confessionApi";
import chatApi from "../../api/chatApi";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const validateMessages = {
  required: "${label} is required!",
};

const BlockReasonType = {
  CONFESSION_POST: "confession_post",
  CONFESSION_COMMENT: "confession_comment",
  PROFILE: "profile",
  QUOTES_MATCH: "quotes_match",
  RANDOM_CHAT: "random_chat",
  GROUP_CHAT: "group_chat",
};

function BlockUserV2() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [blockedData, setBlockedData] = useState([]);
  const [postInfo, setPostInfo] = useState();
  const { search } = useLocation();
  const params = convertURLSearchToObject(search);
  const {
    commentId,
    confessionId,
    id: reportId,
    messageId,
    roomId,
    type,
    userId,
  } = params;

  useEffect(() => {
    fetchUserBlockInfo();
    fetchPostInfo();
    return () => {};
  }, []);

  const fetchUserBlockInfo = async () => {
    if (userId) {
      userApi
        .getUserBlockList(userId)
        .then((res) => {
          if (res.data) {
            setBlockedData(res.data);
          }
        })
        .catch((err) => {
          message.warn(err.message);
        });
    }
  };

  const fetchPostInfo = async () => {
    if (userId) {
      // switch (type) {
      //   case REPORT_TYPE.CONFESSION:
      //     ref = `confession/${confessionId}`;
      //     break;
      //   case REPORT_TYPE.CONFESSION_COMMENT:
      //     ref = `confession/${confessionId}/comments/${commentId}`;
      //     break;
      //   case REPORT_TYPE.CHAT_MESSAGE:
      //     ref = `rooms/${roomId}/messages/${messageId}`;
      //     break;

      //   default:
      //     break;
      // }
      // if (!ref) {
      //   return;
      // }
      let request;
      if (type === REPORT_TYPE.CONFESSION) {
        request = confessionApi.getConfessionDetail(confessionId);
      }
      if (type === REPORT_TYPE.USER) {
        request = userApi.getUserInfo(userId);
      }
      if (type === REPORT_TYPE.CONFESSION_COMMENT) {
        request = confessionApi.getConfessionCommentDetail({
          confessionId,
          commentId,
        });
      }

      if (type === REPORT_TYPE.CHAT_MESSAGE || type === REPORT_TYPE.CHAT) {
        request = chatApi.getMessageDetail({ roomId, messageId });
      }

      if (request) {
        request
          .then((res) => {
            setPostInfo(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const requestDeletePost = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (type === REPORT_TYPE.CONFESSION) {
          await confessionApi.deleteConfession(confessionId);
        }
        if (type === REPORT_TYPE.CHAT_MESSAGE || type === REPORT_TYPE.CHAT) {
          await chatApi.deleteMessage({ roomId, messageId });
        }
        message.success("Delete post success!");
        resolve(true);
      } catch (error) {
        message.warn("Delete document error: " + error.message);
        reject(error);
      }
    });
  };

  const onFinish = async (res) => {
    try {
      setLoading(true);
      if (res?.user?.reportId) {
        await userApi.maskReportDone(res.user.reportId);
        message.success("Change status report success!");
      }
      if (res?.user?.delete) {
        await requestDeletePost();
      }
      await userApi.requestBlock({
        userId: res.user.userId,
        reportId: res.user.reportId,
        reasonType: res.user.reasonType,
        type: res.user.type,
      });
      setLoading(false);
      fetchUserBlockInfo();
      form.resetFields();
      message.success("Success!");
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const onChangeType = (value) => {
    form.setFieldsValue({ type: value });
  };

  const onChangeReason = (value) => {
    form.setFieldsValue({ reason: value });
  };

  const columns = [
    {
      title: "Report Id",
      key: "reportId",
      dataIndex: "reportId",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (item) => {
        let color = "";
        let name = "";
        switch (item) {
          case "1":
            color = "geekblue";
            name = "Waring";
            break;
          case "2":
            color = "green";
            name = "24h";
            break;
          case "3":
            color = "volcano";
            name = "forever";
            break;

          default:
            break;
        }
        return <Tag color={color}>{name.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (item) => {
        let value = item;
        if (item?.vi) {
          value = item.vi;
        }
        return <Typography.Text>{value}</Typography.Text>;
      },
    },
    {
      title: "Admin",
      key: "adminName",
      dataIndex: "adminName",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => (
        <a key={value}>{moment(value).format("DD/MM/YYYY")}</a>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent",
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Block User</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Form
        {...layout}
        form={form}
        initialValues={{
          user: {
            ...params,
            type: "1",
            reportId,
          },
        }}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        {(postInfo && type === REPORT_TYPE.CHAT_MESSAGE) ||
        (postInfo && type === REPORT_TYPE.CHAT) ? (
          <div>
            <Typography.Text>
              <Typography.Text>Message: </Typography.Text>
              {postInfo.map((item, index) => {
                return (
                  <div>
                    {index === 0 ? (
                      <Tag key={index}>
                        <b>
                          {item?.creator?.displayName} : {item?.content}
                        </b>
                      </Tag>
                    ) : (
                      <Tag key={index}>
                        {item?.creator?.displayName} : {item?.content}
                      </Tag>
                    )}
                  </div>
                );
              })}
              {/* <Tag>
                {postInfo?.content || postInfo?.displayName || postInfo?.text}
              </Tag> */}
            </Typography.Text>
          </div>
        ) : (
          <div>
            <Typography.Text>
              <Typography.Text>Post Info: </Typography.Text>
              <Tag>
                {postInfo?.content || postInfo?.displayName || postInfo?.text}
              </Tag>
            </Typography.Text>
            <Image
              src={postInfo?.image?.uri || postInfo?.photoURL}
              width={100}
            />
          </div>
        )}
        <Form.Item
          name={["user", "userId"]}
          label="User Id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "type"]}
          label="Type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Select block type" onChange={onChangeType}>
            <Select.Option value="1">Warning</Select.Option>
            <Select.Option value="2">Block 72h</Select.Option>
            <Select.Option value="3">Block Forever</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name={["user", "reportId"]} label="Report Id">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "confessionId"]} label="Confession Id">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "commentId"]} label="Comment Id">
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "commentChildrenId"]}
          label="Comment Children Id"
        >
          <Input />
        </Form.Item>
        <Form.Item name={["user", "roomId"]} label="Room Id">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "messageId"]} label="Message Id">
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "reasonType"]}
          rules={[
            {
              required: true,
            },
          ]}
          label="Reason Type"
        >
          <Select
            dropdownStyle={{ width: 600 }}
            placeholder="Select block type"
            onChange={onChangeReason}
          >
            {Object.values(BlockReasonType).map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {postInfo && (
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
            name={["user", "delete"]}
            label="Delete document or not?"
          >
            <Radio.Group>
              <Radio value={true}>With Delete Document</Radio>
              <Radio value={false}>Report only</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        {!!blockedData.length && (
          <Table
            dataSource={blockedData}
            columns={columns}
            scroll={{ x: 1300 }}
          />
        )}
      </Form>
    </>
  );
}

export default BlockUserV2;
