import { SET_ENV } from "../actions/config";

export const DEFAULT_ENV = "dev";

const initState = {
  env: DEFAULT_ENV,
};

const config = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_ENV: {
      localStorage.setItem("ENV", payload);
      return { ...state, env: payload };
    }
    default:
      return state;
  }
};

export default config;
