import { Image } from "antd";
import React from "react";
import "./BlurImage.css";

const BlurImage = (props) => {
  if (props.disabledBlur) {
    return <Image {...props} />;
  }
  return (
    <div className="blur-image">
      <Image {...props} />
    </div>
  );
};

export default BlurImage;
