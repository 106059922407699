import { app } from "../../services/firebase";
import { USER_LOGIN, USER_LOGOUT } from "../actions/userInfo";

const initState = {
  isAuthenticated: false,
  displayName: null,
  photoURL: null,
  uid: "",
};

const userInfo = (state = initState, { type, payload }) => {
  switch (type) {
    case USER_LOGIN: {
      const user = { ...state, ...payload, isAuthenticated: true };
      // localStorage.setItem("user", JSON.stringify(user));
      return user;
    }
    case USER_LOGOUT: {
      app.auth().signOut();
      localStorage.removeItem("ENV");
      return initState;
    }
    default:
      return state;
  }
};

export default userInfo;
