import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Breadcrumb } from "@themesberg/react-bootstrap";
import { Empty, Input, message, Select, Table, Button } from "antd";
import { getFirebaseApp, getProjectName } from "../../services/firebase";
import moment from "moment";

const { Option } = Select;

const FIND_TYPE = {
  confessionId: "confessionId",
  commentId: "commentId",
  commentChildrenId: "commentChildrenId",
  userId: "userId",
};

const Trash = () => {
  const [data, setData] = useState([]);
  const [type, setType] = useState(FIND_TYPE.confessionId);
  const [loading, setLoading] = useState(false);

  const findUser = (keyword) => {
    setLoading(true);
    let query = getFirebaseApp()
      .firestore()
      .collection("trash")
      .where(type, "==", keyword);
    console.log(type, keyword);
    query
      .get()
      .then((res) => {
        let val = res.docs.map((item) => ({ ...item.data(), id: item.id }));
        val.sort((a, b) => b.lastActive - a.lastActive);
        setLoading(false);
        setData(val);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const onSearch = (val) => {
    if (!val) {
      return;
    }
    findUser(val);
  };

  const handleChange = (val) => {
    setType(val);
  };

  const onViewDetail = (id) => () => {
    window.open(
      `https://console.firebase.google.com/u/0/project/${getProjectName()}/firestore/data/~2Ftrash~2F${id}`
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "confessionId",
      dataIndex: "confessionId",
      key: "confessionId",
    },
    {
      title: "commentId",
      key: "commentId",
      dataIndex: "commentId",
    },
    {
      title: "commentChildrenId",
      key: "commentChildrenId",
      dataIndex: "commentChildrenId",
    },
    {
      title: "userId",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "created",
      key: "created",
      dataIndex: "created",
      render: (val) => moment(val).format("DD/MM/YYYY"),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (record) => (
        <Button key={record.id} type="ghost" onClick={onViewDetail(record.id)}>
          Open
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Trash</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="align-items-center">
          <Col xl={2}>
            <Select
              defaultValue={type}
              style={{ width: 150 }}
              onChange={handleChange}
            >
              <Option value={FIND_TYPE.userId}>{FIND_TYPE.userId}</Option>
              <Option value={FIND_TYPE.confessionId}>
                {FIND_TYPE.confessionId}
              </Option>
              <Option value={FIND_TYPE.commentId}>{FIND_TYPE.commentId}</Option>
              <Option value={FIND_TYPE.commentChildrenId}>
                {FIND_TYPE.commentChildrenId}
              </Option>
            </Select>
          </Col>
          <Col xl={4}>
            <Input.Search
              placeholder="Search Item"
              allowClear
              size="middle"
              enterButton
              onSearch={onSearch}
              loading={loading}
            />
          </Col>
        </Row>
      </div>

      {!data.length ? (
        <Empty />
      ) : (
        <Table dataSource={data} columns={columns} scroll={{ x: 1300 }} />
      )}
    </>
  );
};
export default Trash;
