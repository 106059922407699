import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, message, Skeleton, Space, Button, Popconfirm } from "antd";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import moment from "moment";
import confessionApi from "../../api/confessionApi";
import BlurImage from "../../components/BlurImage";

const ConfessionPending = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  const fetchData = () => {
    confessionApi
      .getConfessionList({ status: 3 })
      .then((res) => {
        setLoading(false);
        setRequesting(false);
        setData(res.data.map((item) => ({ ...item, key: item.id })));
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const onApprove = (record) => () => {
    setRequesting(true);
    confessionApi
      .approveConfession(record.id)
      .then(() => {
        fetchData();
        message.success("Approve success");
      })
      .catch((err) => {
        setRequesting(false);
        message.error(err.message);
      });
  };
  const onDenied = (record) => () => {
    setRequesting(true);
    confessionApi
      .deniedConfession(record.id)
      .then(() => {
        fetchData();
        message.success("Denied success");
      })
      .catch((err) => {
        setRequesting(false);
        message.error(err.message);
      });
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
    },
    {
      title: "Image",
      key: "image",
      render: (record) => (
        <BlurImage
          disabledBlur={!record.nsfw}
          src={record?.image?.uri}
          preview
          style={{ width: 100 }}
        />
      ),
    },
    {
      title: "content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (value) => (
        <a key={value}>{moment(value).format("DD/MM/YYYY")}</a>
      ),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 250,
      render: (record) => (
        <Space size="middle">
          <Popconfirm title="Sure to denied?" onConfirm={onDenied(record)}>
            <Button key={record.id} type="ghost" danger>
              Denied
            </Button>
          </Popconfirm>
          <Popconfirm title="Sure to approve?" onConfirm={onApprove(record)}>
            <Button key={record.id} type="primary">
              Approve
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const onDeniedAll = async () => {
    try {
      setRequesting(true);
      for (let i = 0; i < selectedRowKeys.length; i++) {
        const itemId = selectedRowKeys[i];
        await confessionApi.deniedConfession(itemId);
      }
      fetchData();
      message.success("Denied success");
    } catch (error) {
      setRequesting(false);
      message.error(error.message);
    }
  };

  const onApprovedAll = async () => {
    try {
      setRequesting(true);
      for (let i = 0; i < selectedRowKeys.length; i++) {
        const itemId = selectedRowKeys[i];
        await confessionApi.approveConfession(itemId);
      }
      fetchData();
      message.success("Approved success");
    } catch (error) {
      setRequesting(false);
      message.error(error.message);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Confession</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Button
        type="primary"
        onClick={onApprovedAll}
        disabled={!hasSelected}
        loading={requesting}
        style={{ marginLeft: 20 }}
      >
        Approved All
      </Button>
      <Button
        type="ghost"
        danger
        onClick={onDeniedAll}
        disabled={!hasSelected}
        loading={requesting}
      >
        Denied All
      </Button>
      {loading ? (
        <Skeleton />
      ) : (
        <Table
          rowSelection={rowSelection}
          dataSource={data}
          columns={columns}
          scroll={{ x: 1300 }}
          loading={{ spinning: requesting }}
        />
      )}
    </>
  );
};
export default ConfessionPending;
