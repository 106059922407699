import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Settings from "./Settings";
import Signin from "./auth/Signin";
import Lock from "./auth/Lock";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import { useDispatch } from "react-redux";
import { dispatchRef } from "../store/ReduxDispatcher";
import FindUser from "./findUser/FindUser";
import { useSelector } from "react-redux";
import RequestFeature from "./requestFeature/RequestFeature";
import PushNotification from "./pushNotification/PushNotification";
import ReportUsers from "./reportUsers/ReportUsers";
import BlockUser from "./blockUser/BlockUser";
import Room from "./room/Room";
import Trash from "./trash/Trash";
import Confession from "./confession/Confession";
import ReportUsersV2 from "./reportUsers/ReportUsersV2";
import BlockUserV2 from "./blockUser/BlockUserV2";
import ConfessionPending from './confessionPending/ConfessionPending';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const userInfo = useSelector((state) => state.userInfo);

  return (
    <Route
      {...rest}
      render={(props) =>
        userInfo.isAuthenticated ? (
          <>
            <Sidebar />
            <main className="content">
              <Navbar />
              <Component {...props} />
              <Footer />
            </main>
          </>
        ) : (
          <Redirect
            to={{
              pathname: Routes.Signin.path,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const HomePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatchRef.current = dispatch;
    return () => {};
  }, []);

  return (
    <Switch>
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
      <RouteWithLoader
        exact
        path={Routes.NotFound.path}
        component={NotFoundPage}
      />
      <RouteWithLoader
        exact
        path={Routes.ServerError.path}
        component={ServerError}
      />

      {/* pages */}
      <RouteWithSidebar
        exact
        path={Routes.DashboardOverview.path}
        component={DashboardOverview}
      />
      <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
      <RouteWithSidebar
        exact
        path={Routes.FindUser.path}
        component={FindUser}
      />
      <RouteWithSidebar
        exact
        path={Routes.RequestFeature.path}
        component={RequestFeature}
      />
      <RouteWithSidebar
        exact
        path={Routes.PushNotification.path}
        component={PushNotification}
      />
      <RouteWithSidebar
        exact
        path={Routes.Confession.path}
        component={Confession}
      />
      <RouteWithSidebar
        exact
        path={Routes.ConfessionPending.path}
        component={ConfessionPending}
      />
      <RouteWithSidebar
        exact
        path={Routes.ReportUsers.path}
        component={ReportUsers}
      />
      <RouteWithSidebar
        exact
        path={Routes.ReportUsersV2.path}
        component={ReportUsersV2}
      />
      <RouteWithSidebar
        exact
        path={Routes.BlockUser.path}
        component={BlockUser}
      />
      <RouteWithSidebar
        path={Routes.BlockUserV2.path}
        component={BlockUserV2}
      />
      <RouteWithSidebar
        exact
        path={Routes.Settings.path}
        component={Settings}
      />
      <RouteWithSidebar exact path={Routes.Room.path} component={Room} />
      <RouteWithSidebar exact path={Routes.Trash.path} component={Trash} />

      {/* components */}
      <RouteWithSidebar
        exact
        path={Routes.Accordions.path}
        component={Accordion}
      />
      <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
      <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
      <RouteWithSidebar
        exact
        path={Routes.Breadcrumbs.path}
        component={Breadcrumbs}
      />
      <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
      <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
      <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
      <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
      <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
      <RouteWithSidebar
        exact
        path={Routes.Pagination.path}
        component={Pagination}
      />
      <RouteWithSidebar
        exact
        path={Routes.Popovers.path}
        component={Popovers}
      />
      <RouteWithSidebar
        exact
        path={Routes.Progress.path}
        component={Progress}
      />
      <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
      <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
      <RouteWithSidebar
        exact
        path={Routes.Tooltips.path}
        component={Tooltips}
      />
      <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
};
export default HomePage;
