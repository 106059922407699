import { axiosInstance, requestGet, requestPost } from "./common";
import { BEEN_ALONE_TOKEN_KEY, DEBUG_END_POINT_APPS } from "./config";
import { RequestBlockParams } from "./userApiType";

export default {
  loginFirebase({ firebaseToken }: { firebaseToken: string }) {
    return new Promise((resolve, reject) => {
      requestPost("users/v1/login/firebase", {
        token: firebaseToken,
      })
        .then((res: any) => {
          const { accessToken } = res?.data || {};
          if (accessToken) {
            localStorage.setItem(BEEN_ALONE_TOKEN_KEY, accessToken);
            axiosInstance.defaults.headers.common = {
              Authorization: `${accessToken}`,
            };
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getReportList({offset, limit}: { offset?: number, limit?: number  } = { offset: 0, limit: 10 }){
    return requestGet(
      `apps/v1/admin/reports/?offset=${offset}&limit=${limit}`,
      { baseURL: DEBUG_END_POINT_APPS }
    );
  },
  maskReportDone(reportId: string){
    return requestPost('/apps/v1/admin/reports/mask-done', { reportId }, { baseURL: DEBUG_END_POINT_APPS })
  },
  requestBlock(params: RequestBlockParams){
    return requestPost('/apps/v1/admin/blocked', params, { baseURL: DEBUG_END_POINT_APPS })
  },
  getUserBlockList(userId: string){
    return requestGet(`/apps/v1/admin/blocked/${userId}`, { baseURL: DEBUG_END_POINT_APPS })
  },
  getUserInfo(userId: string){
    return requestGet(`/apps/v1/admin/user/${userId}`, { baseURL: DEBUG_END_POINT_APPS })
  }
};
