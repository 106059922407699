import React from "react";
import { Col, Row } from "@themesberg/react-bootstrap";

// import {
//   BarChartWidget,
//   TeamMembersWidget,
//   ProgressTrackWidget,
//   RankingWidget,
//   AcquisitionWidget,
// } from "../../components/Widgets";
// import { totalOrders } from "../../data/charts";
import Transaction from "./components/Transaction";
import TotalUsers from "./components/TotalUsers";

const DashboardOverview = () => {
  return (
    <>
      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  <Transaction />
                </Col>
                {/* 
                <Col xs={12} lg={6} className="mb-4">
                  <TotalUsers />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <ProgressTrackWidget />
                </Col> */}
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  <TotalUsers />
                </Col>
                {/* 
                <Col xs={12} className="px-0 mb-4">
                  <RankingWidget />
                </Col>

                <Col xs={12} className="px-0">
                  <AcquisitionWidget />
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default DashboardOverview;
