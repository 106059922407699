import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, message, Skeleton, Input, Space, Button } from "antd";
import { getFirebaseApp, getProjectName } from "../../services/firebase";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import moment from "moment";

const Confession = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = (keyword) => {
    setLoading(true);
    getFirebaseApp()
      .firestore()
      .collection("confession")
      .where("userId", "==", keyword)
      .orderBy("created", "desc")
      .get()
      .then((res) => {
        setLoading(false);
        const val = res.docs.map((item) => ({
          ...item.data(),
          id: item.id,
        }));

        setData(val);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
    return () => {};
  };

  const onSearch = (val) => {
    if (!val) {
      return;
    }
    getData(val);
  };

  const onOpen = (record) => () => {
    window.open(
      `https://console.firebase.google.com/u/0/project/${getProjectName()}/firestore/data/~2Fconfession~2F${
        record.id
      }`
    );
  };

  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (value) => (
        <a key={value}>{moment(value).format("DD/MM/YYYY")}</a>
      ),
      fixed: "left",
      width: 150,
    },
    {
      title: "content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 250,
      render: (record) => (
        <Space size="middle">
          <Button key={record.id} type="ghost" onClick={onOpen(record)}>
            Open
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Confession</Breadcrumb.Item>
            <Input.Search
              placeholder="User ID"
              allowClear
              size="middle"
              enterButton
              onSearch={onSearch}
              loading={loading}
            />
          </Breadcrumb>
        </div>
      </div>
      {loading ? (
        <Skeleton />
      ) : (
        <Table dataSource={data} columns={columns} scroll={{ x: 1300 }} />
      )}
    </>
  );
};
export default Confession;
