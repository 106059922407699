import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { message, Tag, List, Typography } from "antd";
import { getFirebaseApp } from "../../services/firebase";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import { useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

const Room = (props) => {
  const { roomId, messageId } = props.location?.state || {};
  const history = useHistory();
  const [data, setData] = useState([]);
  const [roomInfo, setRoomInfo] = useState({});

  useEffect(() => {
    getFirebaseApp()
      .firestore()
      .collection("rooms")
      .doc(roomId)
      .get()
      .then((res) => {
        setRoomInfo(res.data());
      })
      .catch((err) => {
        message.warn(err);
      });
    getFirebaseApp()
      .firestore()
      .collection("rooms")
      .doc(roomId)
      .collection("messages")
      .orderBy("createdAt", "desc")
      .limit(100)
      .get()
      .then((res) => {
        const arr = [];
        res.docs.forEach((item) => {
          arr.push({ ...item.data(), id: item.id });
        });
        setData(arr);
        scrollToItem();
      })
      .catch((err) => {
        message.warn(err);
      });

    return () => {};
  }, []);

  const scrollToItem = () => {
    if (!messageId) {
      return;
    }
    const element = document.getElementById(messageId);
    const y = element?.getBoundingClientRect()?.top + window.pageYOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={history.goBack}>
              Report Users
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Room</Breadcrumb.Item>
            <Breadcrumb.Item active>{roomId}</Breadcrumb.Item>
            <Breadcrumb.Item active>{messageId}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <List
        dataSource={data}
        renderItem={(item) => {
          return (
            <List.Item id={item.id} key={item.id}>
              <List.Item.Meta
                title={
                  <Tag
                    color={
                      item.user._id === roomInfo?.users?.[0] ? "green" : "blue"
                    }
                  >
                    {item.user._id.slice(0, 3)}*********
                  </Tag>
                }
                description={
                  <Typography.Text
                    style={item.id === messageId && { color: "red" }}
                  >
                    {item.text}
                  </Typography.Text>
                }
              />
              <Typography.Text>
                {moment(item.createdAt).format("hh:mm:ss DD/MM/YYYY")}
              </Typography.Text>
            </List.Item>
          );
        }}
      ></List>
    </>
  );
};
export default Room;
