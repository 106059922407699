import React from "react";
import { Col, Button } from "@themesberg/react-bootstrap";
import { Select, Typography, message } from "antd";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { getFirebaseApp } from "../../services/firebase";
import ReduxDispatcher from "../../store/ReduxDispatcher";
import { userLogin, userLogout } from "../../store/actions/userInfo";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "firebase";
import { useEffect } from "react";
import { setEnv } from "../../store/actions/config";
import { DEFAULT_ENV } from "../../store/reducers/config";
import Footer from "../../components/Footer";
import userApi from "../../api/userApi";
import { axiosInstance } from "../../api/common";
import { getEndPoint } from "../../api/config";

const Signin = () => {
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const onChangeState = async (user) => {
    if (!user) {
      ReduxDispatcher(userLogout());
      return;
    }
    const { displayName, email, emailVerified, photoURL, uid } = user;
    getFirebaseApp()
      .firestore()
      .collection("roles")
      .doc(uid)
      .get()
      .then(async (res) => {
        if (!res.exists) {
          message.warn("You don't have permission to login this website!");
          getFirebaseApp().auth().signOut();
          return;
        }
        const firebaseToken = await getFirebaseApp()
          .auth()
          .currentUser.getIdToken();
        await userApi.loginFirebase({ firebaseToken });
        ReduxDispatcher(
          userLogin({ displayName, email, emailVerified, photoURL, uid })
        );
        history.replace(from);
        message.success("Login success!");
      })
      .catch((err) => {
        message.warn(err.message || "Something went wrong, please try again!");
      });
  };

  useEffect(() => {
    getFirebaseApp().auth().onAuthStateChanged(onChangeState);
    return () => {};
  }, []);

  const onChange = (res) => {
    axiosInstance.defaults.baseURL = getEndPoint(res);
    ReduxDispatcher(setEnv(res));
  };

  const onSignin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const res = await getFirebaseApp().auth().signInWithPopup(provider);
      onChangeState(res.user);
    } catch (error) {
      message.warn(error.message || "Login fail!");
    }
  };

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <div
        style={{
          backgroundImage: `url(${BgImage})`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "80%",
        }}
      >
        <Col
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
            <div className="text-center text-md-center mb-4 mt-md-0">
              <h4 className="mb-0">Welcome to</h4>
              <h3 className="mb-0">Been Alone Admin</h3>
            </div>

            <Typography.Text style={{ marginTop: 32 }} disabled>
              Select environment
            </Typography.Text>
            <Select
              showSearch
              style={{ width: "100%", marginBottom: 24, marginTop: 12 }}
              defaultValue={DEFAULT_ENV}
              optionFilterProp="children"
              onChange={onChange}
              size="large"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="dev">Development</Select.Option>
              <Select.Option value="pro">Production</Select.Option>
            </Select>
            <Button
              onClick={onSignin}
              variant="primary"
              type="submit"
              className="w-100"
            >
              Login
            </Button>
          </div>
        </Col>
      </div>
      <div style={{ marginLeft: "10%", marginRight: "10%" }}>
        <Footer />
      </div>
    </div>
  );
};
export default Signin;
