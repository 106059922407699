import {  requestDelete, requestGet,  } from "./common";
import { DEBUG_END_POINT_APPS } from "./config";

export default {
  getMessageDetail({
    roomId,
    messageId,
  }: {
    roomId: string;
    messageId: string;
  }) {
    return requestGet(
      `/chat/v1/admin/room/${roomId}/message?from=${messageId}`,
      {
        baseURL: DEBUG_END_POINT_APPS,
      }
    );
  },
  deleteMessage({
    roomId,
    messageId,
  }: {
    roomId: string;
    messageId: string;
  }) {
    return requestDelete(`/chat/v1/admin/room/${roomId}/${messageId}`, {
      baseURL: DEBUG_END_POINT_APPS,
    });
  },
};
