import { Select } from "antd";
import React from "react";
import { blue, green, red, yellow } from "@ant-design/colors";

export const FILTER_VALUE = {
  ALL: "all",
  ACCEPTED: "accepted",
  REQUESTING: "requesting",
  CANCELED: "canceled",
  FINISHED: "finished",
};

export const getStatusColor = (val) => {
  switch (val) {
    case FILTER_VALUE.REQUESTING:
      return blue.primary;
    case FILTER_VALUE.ACCEPTED:
      return yellow.primary;
    case FILTER_VALUE.FINISHED:
      return green.primary;
    case FILTER_VALUE.CANCELED:
      return red.primary;
    default:
      return blue.primary;
  }
};

const RequestActions = ({ defaultValue, onChangeValue, enableAll }) => {
  const onChangeStatus = (val) => {
    onChangeValue(val);
  };

  return (
    <Select
      defaultValue={defaultValue || FILTER_VALUE.REQUESTING}
      onChange={onChangeStatus}
      style={{ minWidth: 120 }}
    >
      {enableAll && (
        <Select.Option
          style={{ color: getStatusColor(FILTER_VALUE.ALL) }}
          value={FILTER_VALUE.ALL}
        >
          {FILTER_VALUE.ALL.toUpperCase()}
        </Select.Option>
      )}
      <Select.Option
        style={{ color: getStatusColor(FILTER_VALUE.REQUESTING) }}
        value={FILTER_VALUE.REQUESTING}
      >
        {FILTER_VALUE.REQUESTING.toUpperCase()}
      </Select.Option>
      <Select.Option
        style={{ color: getStatusColor(FILTER_VALUE.ACCEPTED) }}
        value={FILTER_VALUE.ACCEPTED}
      >
        {FILTER_VALUE.ACCEPTED.toUpperCase()}
      </Select.Option>
      <Select.Option
        style={{ color: getStatusColor(FILTER_VALUE.FINISHED) }}
        value={FILTER_VALUE.FINISHED}
      >
        {FILTER_VALUE.FINISHED.toUpperCase()}
      </Select.Option>
      <Select.Option
        style={{ color: getStatusColor(FILTER_VALUE.CANCELED) }}
        value={FILTER_VALUE.CANCELED}
      >
        {FILTER_VALUE.CANCELED.toUpperCase()}
      </Select.Option>
    </Select>
  );
};
export default RequestActions;
