import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBook,
  faChartPie,
  faCog,
  faFlagCheckered,
  faSearch,
  faTrash,
  faUserFriends,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <Nav className="flex-column pt-3 pt-md-0">
            <NavItem
              title="Overview"
              link={Routes.DashboardOverview.path}
              icon={faChartPie}
            />
            <NavItem
              title="Find User"
              icon={faSearch}
              link={Routes.FindUser.path}
            />
            <NavItem
              title="Request New Feature"
              icon={faUserFriends}
              link={Routes.RequestFeature.path}
            />
            <NavItem
              title="Push Notification"
              icon={faBell}
              link={Routes.PushNotification.path}
            />
            <NavItem
              title="Confession"
              icon={faBook}
              link={Routes.Confession.path}
            />
            <NavItem
              title="Confession Pending"
              icon={faBook}
              link={Routes.ConfessionPending.path}
            />
            <NavItem
              title="Report Users"
              icon={faFlagCheckered}
              link={Routes.ReportUsers.path}
            />
            <NavItem
              title="Report Users V2"
              icon={faFlagCheckered}
              link={Routes.ReportUsersV2.path}
            />
            <NavItem
              title="Block User"
              icon={faUserLock}
              link={Routes.BlockUser.path}
            />
            <NavItem
              title="Block User V2"
              icon={faUserLock}
              link={Routes.BlockUserV2.path}
            />
            <NavItem title="Trash" icon={faTrash} link={Routes.Trash.path} />
            <Dropdown.Divider className="my-3 border-indigo" />
            <NavItem
              title="Settings"
              icon={faCog}
              link={Routes.Settings.path}
            />
          </Nav>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
export default Sidebar;
