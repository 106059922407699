import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import { Form, Input, Button, Descriptions, message } from "antd";
import { getFirebaseApp } from "../../services/firebase";
import { sendNotification } from "../../utils/Helper";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function PushNotification() {
  const [loading, setLoading] = useState(false);

  const getUserInfoAndPush = ({ userId, title, body, data }) => {
    return new Promise((resolve, reject) => {
      getFirebaseApp()
        .firestore()
        .collection("users")
        .doc(userId)
        .get()
        .then((res) => {
          resolve(sendNotification("admin", res.data(), data, { title, body }));
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const onFinish = async (res) => {
    setLoading(true);
    const { userIDs, title, body, data = {} } = res.user;
    const dataParse = JSON.parseSafe(data);
    const arrUserID = userIDs.split(",");
    const arrPro = [];

    for (let i = 0; i < arrUserID.length; i++) {
      const userId = arrUserID[i];
      arrPro.push(getUserInfoAndPush({ userId, title, body, data: dataParse }));
    }
    Promise.all(arrPro)
      .then(() => {
        setLoading(false);
        message.success("Push notification success!");
      })
      .catch((err) => {
        setLoading(false);
        message.warn("Push notification error: " + err);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent",
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Push Notification</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          name={["user", "userIDs"]}
          label="UserIDs"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={["user", "title"]} label="Notification title ">
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "body"]}
          label="Notification text"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={["user", "data"]} label="Data">
          <Input aria-multiline />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        <Descriptions title="Data Example" layout="vertical" bordered>
          <Descriptions.Item label="Popup">
            {`{
                "buttons": [
                  { "title": "Đóng", "closeable": true, "border": true },
                  { "title": "Chat", "refId": "Chat", "closeable": true },
                ],
                "description":
                  "Tính năng được mong chờ là Trò chuyện với người lạ đã được ra mắt! Bạn hãy thử trải nghiệm nhé!",
                "showPopup": true,
              }`}
          </Descriptions.Item>
          <Descriptions.Item label="Open Screen">
            {`{ "refId": "ChatConversation", "params": JSON.stringify({ roomId }) }`}
          </Descriptions.Item>
        </Descriptions>
      </Form>
    </>
  );
}

export default PushNotification;
