
export const isProduction = () => {
  return localStorage.getItem("ENV") === "pro";
};

const SUFFIX = isProduction() ? '' : '-dev';
export let END_POINT = `https://api${SUFFIX}.beenalone.com/`;
export const getEndPoint = (env: any)=>{

  return `https://api${env === 'pro' ? '' : '-dev'}.beenalone.com/`;
}
export const BEEN_ALONE_TOKEN_KEY = '@been-alone-token'
export let DEBUG_END_POINT_APPS: string;

// Enable this for test in local

// END_POINT = 'http://localhost:9503/';
// DEBUG_END_POINT_APPS = 'http://localhost:9501/';

