import { createStore } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import reducers from "./reducers";
import storageSession from "redux-persist/lib/storage/session";

const persistedReducer = persistReducer(
  {
    storage: storageSession,
    key: "beenalone",
  },
  reducers
);

const store = createStore(persistedReducer);
let persistor = persistStore(store);
export { store, persistor };
