import { message, Skeleton, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getFirebaseApp } from "../../../services/firebase";
import { openFirestoreCollection } from "../../../utils/Helper";

function Transaction() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFirebaseApp()
      .firestore()
      .collection("transactions")
      .get()
      .then((res) => {
        setLoading(false);
        const val = res.docs
          .filter((item) => !!item.data()?.status)
          .map((item) => ({ ...item.data(), id: item.id }));

        setData(val);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
    return () => {};
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      ellipsis: {
        showTitle: false,
      },
      render: (id) => (
        <Typography.Link
          onClick={() => {
            openFirestoreCollection({ collectionName: "transactions", id });
          }}
        >
          {id}
        </Typography.Link>
      ),
      width: 300,
    },
    {
      title: "Combo",
      dataIndex: "product_id",
      key: "product_id",
      width: 100,
      render: (value) =>
        !value ? null : (
          <Tag color="blue">{value?.slice?.(value?.indexOf("_") + 1)}</Tag>
        ),
    },
    {
      title: "Status",
      dataIndex: "platform",
      key: "platform",
      render: (status) => (
        <Tag
          color={status && status === "android" ? "green" : "gold"}
          key={status}
        >
          {!status ? "IOS" : status?.toUpperCase()}
        </Tag>
      ),
      width: 120,
    },
    {
      title: "Transaction Info",
      dataIndex: "transactionReceipt",
      key: "transactionReceipt",
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  return (
    <>
      <Typography.Text
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          fontSize: 30,
          fontWeight: "bold",
        }}
      >
        Transactions
      </Typography.Text>
      {loading ? <Skeleton /> : <Table dataSource={data} columns={columns} />}
    </>
  );
}

export default Transaction;
